import axios from "axios";
export function request (config){
  const  instance = axios.create({
    //根路径
    baseURL:'https://service.coclyun.top/house',
    timeout:5000,
  })
  //axios 拦截器
  //请求拦截
  instance.interceptors.request.use(config => {
    //每个请求头文件加入 token
    config.headers.Authorization = window.localStorage.getItem('token');
    return config
  },err => {
    console.log(err)
  })
  //响应拦截
  instance.interceptors.response.use(res => {
    if('token' in res.headers){
      window.localStorage.setItem('token',res.headers.token);
    }
    return res.data
  },err => {
    console.log(err)
  })
  // 发送真正的网络请求
  return instance(config)
}
